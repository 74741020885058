





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import BaseForm from '../components/BaseForm.vue';
import {
  FormElement,
  FormElementType,
  FormElementOptions,
} from '../shared/types/form-element.class';
import { Category } from '../api/categories/category.model';

// The @Component decorator indicates the class is a Vue component
@Component({
  components: {
    BaseForm,
  },
})
export default class CategoryForm extends Vue {
  @Prop()
  category!: Category;

  formElements: FormElement[][] = [
    [
      new FormElement('title', 'Title', new FormElementOptions(), 'required'),
      new FormElement('type', 'Type', new FormElementOptions(), 'required'),
    ],
    [
      new FormElement(
        'image',
        'Image',
        new FormElementOptions(),
        '',
        FormElementType.IMAGE,
      ),
    ],
  ];
}
