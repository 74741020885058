











































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';

import BaseTable from '../components/BaseTable.vue';
import BaseHeader from '../components/BaseHeader.vue';
import CategoryForm from '../components/CategoryForm.vue';

import { Column } from '../shared/types/column.class';
import { Filter } from '../shared/types/filter.class';

import { Category } from '../api/categories/category.model';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const categoriesModule = namespace('categories');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable, BaseHeader, CategoryForm } })
export default class Categories extends Vue {
  $refs!: Vue['$refs'] & {
    detailModal: BModal;
    createModal: BModal;
  };

  isLoading = false;

  tabIndex = 0;
  columns: Column[] = [
    new Column('title', 'Title'),
    new Column('type', 'Type'),
  ];

  categoryForDetail: Category = new Category();
  newCategory: Category = new Category();

  filter: Filter = new Filter();

  @categoriesModule.Getter('all')
  categories!: Category[];

  @categoriesModule.Getter('pagination')
  pagination!: PaginateResult<Category>;

  @categoriesModule.Action('fetchAll')
  fetchAll!: (filter?: Filter) => Promise<Category[]>;

  @categoriesModule.Action('delete')
  delete!: (id?: string) => Promise<void>;

  @categoriesModule.Action('create')
  create!: (category: Category) => Promise<Category>;

  @categoriesModule.Action('update')
  update!: (category: Category) => Promise<Category>;

  doSearch(searchString: string) {
    this.filter.setSearch(searchString);
    this.doFetchAll();
  }

  doSort(field: string) {
    this.filter.setSort(field);
    this.doFetchAll();
  }

  openCreateModal() {
    this.$refs.createModal.show();
  }

  openDetail(category: Category) {
    this.categoryForDetail = category;
    this.$refs.detailModal.show();
  }

  async doDelete(category: Category) {
    await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${category.title}?`,
      {
        title: 'Confirm delete',
        okVariant: 'danger',
        centered: true,
      },
    );
    this.delete(category._id);
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll(this.filter);
    this.isLoading = false;
  }

  created() {
    this.doFetchAll();
  }
}
